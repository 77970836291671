import React from 'react';
import PropTypes from "prop-types";

const AboutPartner = ({name, description, image, links}) => {
  return (
    <div className="flex flex-col sm:flex-row gap-x-4 items-center text-center sm:text-start sm:px-10 mb-10">
      <img src={image} className="w-full aspect-[345/200] rounded-none sm:rounded object-cover object-[top_25%_right_0px] sm:w-[130px] sm:h-[145px]"/>
      <div className="flex flex-col sm:gap-y-2 px-10 sm:px-0">
        <h4 className="text-xl my-0 pb-0">{name}</h4>
        <div className="flex flex-col-reverse sm:flex-col gap-y-2">
          <p className="my-0">{description}</p>
          <div className="flex flex-row justify-center sm:justify-start gap-x-4">
            {links.map(({ label, href }) => <a key={label} href={href} className="text-blue-500 hover:text-blue-400">{label}</a> )}
          </div>
        </div>
      </div>
    </div>
  );
};

AboutPartner.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  })).isRequired,
};

export default AboutPartner;