import React from 'react';
import TargetAudiencesOption from "./TargetAudiencesOption";

const TargetAudiences = () => {
  return (
    <div className="basier max-w-5xl m-auto pt-20 gap-y-5 flex flex-col px-5 text-center md:text-start">
      <h2 className="font-semibold text-2xl text-theme-blue-dark">For everyone in the life sciences</h2>
      <p>Flow has been designed from the ground up by a team of developers and sequencing domain experts, designed to guide you from sequencing to publication-ready data</p>
      <div className="flex flex-col md:flex-row gap-x-10 gap-y-10 justify-center mt-10 text-gray-800">
        <TargetAudiencesOption
          title="For biologists"
          content="Flow allows you to run validated, tested bioinformatics pipelines with no coding required - directly from a clean web interface"
          link="/for/biologists/" />
        <TargetAudiencesOption
          title="For bioinformaticians"
          content="You can easily write pipeline GUIs for your workflows with just one JSON file. You can also use Flow via our powerful API for complex data manipulation"
          link="/for/developers" />
        <TargetAudiencesOption
          title="For lab/institute leaders"
          content="Flow is designed to enhance team collaboration through easy sharing of data between groups, and advanced permissions and data security"
          link="for/institutes" />
      </div>
    </div>
  );
};

TargetAudiences.propTypes = {

};

export default TargetAudiences;