import React from 'react';

import logoUcl from '../../images/logos/ucl@3x.png';
import logoUkdri from '../../images/logos/dri@3x.png';
import logoCrick from '../../images/logos/crick@3x.png';
import logoKings from '../../images/logos/kings@3x.png';

import dri from "../../images/dri.svg";
import marylyon from "../../images/mary-lyon.svg";
import kcl from "../../images/kcl.png";
import sidb from "../../images/sidb.png";
import nottingham from "../../images/nottingham.png";
import edinburgh from "../../images/edinburgh.png";
import crick from "../../images/crick.png";
import ucl from "../../images/ucl.png";
import swc from "../../images/swc.png";
import imperial from "../../images/imperial.png";

const CustomerLogos = () => {

  return (
    <section class="max-w-5xl m-auto pt-20 bg-white text-gray-800 pb-4">
      <h2 className="text-center text-slate-500 text-2xl font-bold">Trusted by teams at</h2>
      
      <div class="logos group relative overflow-hidden whitespace-nowrap  py-10 [mask-image:_linear-gradient(to_right,_transparent_0,_white_128px,white_calc(100%-128px),_transparent_100%)]">
        <div class="animate-slide-left-infinite group-hover:animation-pause inline-block w-max">
          <img class="mx-4 sm:mx-8 inline h-14" src={kcl} alt="KCL" />
          <img class="mx-4 sm:mx-8 inline h-16" src={nottingham} alt="Nottingham" />
          <img class="mx-4 sm:mx-8 inline h-10" src={dri} alt="DRI" />
          <img class="mx-4 sm:mx-8 inline h-14" src={marylyon} alt="MaryLyon" />
          <img class="mx-4 sm:mx-8 inline h-20" src={edinburgh} alt="Edinburgh" />
          <img class="mx-4 sm:mx-8 inline h-24" src={crick} alt="Crick" />
          <img class="mx-4 sm:mx-8 inline h-11" src={ucl} alt="UCL" />
          <img class="mx-4 sm:mx-8 inline h-16" src={imperial} alt="Imperial" />
        </div>

        <div class="animate-slide-left-infinite group-hover:animation-pause inline-block w-max">
          <img class="mx-4 sm:mx-8 inline h-14" src={kcl} alt="KCL" />
          <img class="mx-4 sm:mx-8 inline h-16" src={nottingham} alt="Nottingham" />
          <img class="mx-4 sm:mx-8 inline h-10" src={dri} alt="DRI" />
          <img class="mx-4 sm:mx-8 inline h-14" src={marylyon} alt="MaryLyon" />
          <img class="mx-4 sm:mx-8 inline h-20" src={edinburgh} alt="Edinburgh" />
          <img class="mx-4 sm:mx-8 inline h-24" src={crick} alt="Crick" />
          <img class="mx-4 sm:mx-8 inline h-11" src={ucl} alt="UCL" />
          <img class="mx-4 sm:mx-8 inline h-16" src={imperial} alt="Imperial" />
        </div>
      </div>
    </section>
  );
};

CustomerLogos.propTypes = {

};

export default CustomerLogos;