import React from 'react';
import PropTypes from "prop-types";
import SelectorArrow from "../sprites/SelectorArrow";

const selectedContainerClass = 'flex flex-row items-center -ml-[16px] text-white lg:text-theme-blue-dark';
const unselectedContainerClass = 'flex flex-row items-center text-gray-300 lg:text-gray-500 ';

const FlowDescriptorOptionsOption = ({ isSelected, onSelect, children }) => {
  return (
    <div className={isSelected ? selectedContainerClass : unselectedContainerClass}>
      { !!isSelected && (
        <SelectorArrow className="sr-hidden mr-[6px]" />
      )}
      <p className="cursor-pointer" onClick={onSelect}>{ children }</p>
    </div>
  );
};

FlowDescriptorOptionsOption.propTypes = {
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default FlowDescriptorOptionsOption;