import * as React from "react";

const ArrowSvg = (props) => (
  <svg
    width={32}
    height={58}
    viewBox="0 0 32 58"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m16.024 57.572 15.664-27.13H.361l15.663 27.13ZM13.311.412v32.743h5.426V.412h-5.426Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowSvg;
