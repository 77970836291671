import * as React from "react";

const SelectorArrow = (props) => (
  <svg
    width={10}
    height={16}
    viewBox="0 0 11 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.15 8.282 1.158 3.288a1.264 1.264 0 0 1-.38-.93c0-.367.126-.676.38-.93l.77-.77c.254-.254.564-.38.93-.38.367 0 .677.126.93.38l6.7 6.689c.254.254.38.564.38.93 0 .367-.126.676-.38.93l-6.7 6.69c-.253.253-.563.38-.93.38-.366 0-.676-.127-.93-.38l-.77-.77a1.258 1.258 0 0 1-.38-.926c0-.363.126-.674.38-.935L6.15 8.282Z"
      fill="currentColor"
    />
  </svg>
);

export default SelectorArrow;
