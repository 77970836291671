import React from "react";
import Base from "./Base";
import GradientContainer from "../components/GradientContainer";
import AboutPartner from "../components/AboutPartner";

import prof_jern from '../images/avatars/prof-jernej-ule@3x.png';
import dr_char from '../images/avatars/dr-charlotte-capitanchik@3x.png';
import Footer from "../components/Footer";
import GetStartedForm from "../components/GetStartedForm";
import ContactForm from "../components/ContactForm";

const AboutPage = () => {
  return (
    <Base>
      <article className="prose max-w-5xl mx-auto mt-48">
        <div className="px-10">
          <h1>About the Flow platform</h1>
          <p>
            Flow is owned and developed by <a href="https://goodwright.com" className="text-blue-500 hover:text-blue-400">Goodwright</a>, a
            software company born out of academia. Goodwright is run by
            co-founders Dr. Sam Ireland and Dr. Alex Harston, who started the company during their PhDs to provide
            high-quality software products for scientists. Alex and Sam are both software developers who come from biological and biomedical backgrounds respectively.
          </p>

          <h2>Flow has developed out of academia</h2>
          <p>
            Flow is a collaboration, and has grown out of nearly a decade of thinking about how to build the best web
            platform for bioinformatic sequencing analysis. It has been designed in conjunction with some of the world
            leaders in several different biological fields from the Francis Crick Institute, Okinawa Institute of Science and Technology, UCL, and King’s College London.
          </p>

          <h2>Advisory Board</h2>
        </div>

        <AboutPartner
          image={prof_jern} name="Prof. Jernej Ule"
          description="Jernej is the Director of the Dementia Research Institute at King’s College London, and group leader at the Francis Crick Insitute. Jernej is the inventor of a major transcriptomic technique, CLIP (crosslinking and immunoprecipitation) for protein-RNA interactions, and his lab focuses on understanding RNA networks."
          links={[
            {label: 'Publications', href: 'https://scholar.google.co.uk/citations?user=MOwgMkEAAAAJ'},
            {label: 'GitHub', href: 'https://github.com/ulelab'},
            {label: 'Website', href: 'https://www.ulelab.info/'},
          ]}
        />
        {/* <AboutPartner
          image={dr_char} name="Dr. Charlotte Capitanchik"
          description="Charlotte has a PhD in Computational Molecular Genetics from the Francis Crick Institute, and an MSc in Bioinformatics from the University of Edinburgh. She is Goodwright’s Head of Bioinformatics, and a world-expert in computational biology, bioinformatics and molecular genetics."
          links={[
            {label: 'Publications', href: '#'},
            {label: 'GitHub', href: '#'},
            {label: 'Website', href: '#'},
          ]}
        /> */}
      </article>

      <div className="flex flex-col items-center lg:flex-row max-w-5xl m-auto px-10 pt-16 lg:pt-4">
        <div className="flex-1 space-y-2 prose text-center lg:text-start">
          <h2 className="text-xl font-semibold my-0">Collaborate with us</h2>
          <p>
            We’re always looking for new scientific collaborators to provide feedback on the platform and develop new pipelines. Feel free to get in touch with us and let’s talk.
          </p>
        </div>

        <ContactForm />

      </div>
      <Footer />
    </Base>
  );
};

AboutPage.propTypes = {};

export default AboutPage;