import React from "react";
import Nav from "../components/Nav";
import PropTypes from "prop-types";

const Base = props => {
  return (
    <div>
      <Nav isDark={props.isDark} />
      <main className="">
        {props.children}
      </main>
    </div>
  );
};

Base.propTypes = {
  isDark: PropTypes.bool
};

export default Base;