import React from "react";
import HomeHeader from "../components/home/HomeHeader";
import Base from "./Base";
import FlowDescriptorOptions from "../components/home/FlowDescriptorOptions";
import CustomerLogos from "../components/home/CustomerLogos";
import FeaturesGrid from "../components/home/FeaturesGrid";
import screenshotSamples from '../images/samples.webp';
import TargetAudiences from "../components/home/TargetAudiences";
import Community from "../components/home/Community";
import Footer from "../components/Footer";
import YoutubeEmbed from "../components/home/YoutubeEmbed";
import FooterContact from "../components/home/FooterContact";
import HowDoesFlowWork from "../components/home/HowDoesFlowWork";

const HomePage = () => {
  return (
    <Base>
      <HomeHeader />
      <HowDoesFlowWork />
      <YoutubeEmbed embedId="RUmkTqIcr_w"/>
      <CustomerLogos />
      <FlowDescriptorOptions />
      <div className="flex flex-col items-center relative my-20 px-10">
        <div className="absolute inset-0 bg-slate-700 -z-10 h-[60%] mt-[25%] md:m-auto" />
        <img
          alt="A screenshot of the execution pipeline showing a completed process execution"
          src={screenshotSamples}
          className="w-full max-w-lg mx-auto md:max-w-5xl md:mx-0 shadow-lg rounded-md"/>
      </div>
      <FeaturesGrid />
      <TargetAudiences />
      <Community />
      <FooterContact />
      <Footer isDark />
    </Base>
  );
};

HomePage.propTypes = {
  
};

export default HomePage;