import React from 'react';
import Avatar from "../Avatar";
import GetStartedForm from "../GetStartedForm";

const FooterContact = () => {
  return (
    <div className="p-10 md:p-24 mt-10 md:mt-16 bg-[#161C28] text-[#A6A6A6]">
      <div className="flex flex-col lg:flex-row max-w-7xl m-auto">

        <div className="lg:w-1/2 flex flex-col gap-y-5 text-center lg:text-start mb-10">
          <h2 className="text-4xl text-white font-semibold">Who uses Flow?</h2>
          <p>
					Flow has been designed in collaboration with computational and experimental biologists at the Francis Crick Institute, Dementia Research Institute, and King’s College London
          </p>
          <p>
					Flow’s users span a range of different universities across the UK, Europe and the US.
          </p>
        </div>

        <GetStartedForm />
      </div>
    </div>
  );
};

FooterContact.propTypes = {

};

export default FooterContact;