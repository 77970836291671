import React from 'react';
import {useLocation} from "react-router";

const IllustratedHeaderNavigation = () => {
  let location = useLocation();

  return (
    <>
      <div className="flex-col hidden items-center gap-y-4 font-semibold text-center">
        { links.map(link => (
          <a
            className={`${location.pathname === link.href ? 'bg-[#444] text-[#1BB8D9] flex flex-row items-center' : ''} font-sm transition px-5 py-2 rounded`}
            href={link.href}>
            {location.pathname === link.href  && <div className="inline-block mr-2 rounded-full bg-[#1BB8D9] w-[8px] h-[8px]" /> }
            {link.label}
          </a>
        ))}
        <hr className="height-[1px] border-[#4F4F4F] w-full" />
      </div>
      <div className="hidden lg:flex flex-row mt-10 justify-around">
        { links.map(link => (
          <a
            className={`${location.pathname === link.href ? 'bg-[#1BB8D9BF]' : ''} font-lg transition px-5 py-2 rounded hover:bg-[#1BB8D9]`}
            href={link.href}>
            {link.label}
          </a>
        ))}
      </div>
    </>
  );
};

const links = [
  { href: '/for/biologists/', label: 'Wet Lab Biologists' },
  { href: '/for/developers/', label: 'Bioinformaticians & Developers' },
  { href: '/for/institutes/', label: 'Institutes' },
];

IllustratedHeaderNavigation.propTypes = {

};

export default IllustratedHeaderNavigation;