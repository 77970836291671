import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const ResponsiveLinkButton = ({ href, children, isExternal, className: initialClassName = '' }) => {
  const Component = isExternal ? 'a' : Link;
  const props = isExternal ? { href } : { to: href };

  const className = `rounded bg-theme-blue-dark hover:bg-theme-blue-light transition px-5 py-2 text-sm text-center text-white sm:mx-auto md:mx-0 w-full sm:max-w-sm md:w-max ${initialClassName}`;

  return (
    <Component
      {...props}
      className={className}>
      { children }
    </Component>
  );
};

ResponsiveLinkButton.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isExternal: PropTypes.bool,
  className: PropTypes.string,
};

export default ResponsiveLinkButton;