import React from 'react';

import database from '../../images/icons/database.svg';
import passiveIncome from '../../images/icons/passive-income.svg';
import networking from '../../images/icons/networking.svg';

const EverythingYouNeed = () => {
  return (
    <div className="max-w-7xl p-10 lg:p-20 mx-auto">
      <div className="flex flex-col lg:flex-row">
        <div className="space-y-4 lg:space-y-8 max-w-[640px] pr-[78px]">
          <h2 className="text-2xl lg:text-5xl font-semibold pb-4 max-w-[500px]">
            <span className="text-[#3B59C3]">Everything you need, all in</span> one place
          </h2>

          <p>
            Flow’s strengths lie in its level of integration - your pipelines and file storage are all kept in the same
            place. At a high-level, you simply do 3 things: Upload your files, select a pipeline to run and pick the
            file to run it on, and then view your results.
          </p>

          <p>
            Flow is designed from the ground-up to a) keep your data consistent, b) perform all the complex workflow
            processing in the background so you don’t have to think about it, and c) give you beautiful data
            visualisations to generate new insights
          </p>
        </div>

        <div>
          <div
            className="flex flex-col md:flex-row gap-x-4 justify-center md:justify-start items-center my-16 lg:my-4 text-center md:text-start">
            <img src={database} className="w-[75px] h-[75px]"/>
            <div className="flex flex-col gap-y-4 mt-4 md:gap-y-2">
              <h3 className="text-lg font-semibold">All your input files</h3>
              <p className="text-sm">
                Upload all your FASTA, BAM and GTF files to Flow to run your analyses, and keep
                them stored in a simple, safe way
              </p>
            </div>
          </div>

          <div
            className="flex flex-col md:flex-row gap-x-4 justify-center md:justify-start items-center my-16 lg:my-4 text-center md:text-start">
            <img src={passiveIncome} className="w-[75px] h-[75px]"/>
            <div className="flex flex-col gap-y-4 mt-4 md:gap-y-2">
              <h3 className="text-lg font-semibold">Complete workflow metadata</h3>
              <p className="text-sm">
                All aspects of your pipeline run are saved - what files it took, how long the workflow ran for, what
                outputs it produced, and more
              </p>
            </div>
          </div>

          <div
            className="flex flex-col md:flex-row gap-x-4 justify-center md:justify-start items-center my-16 lg:my-4 text-center md:text-start">
            <img src={networking} className="w-[75px] h-[75px]"/>
            <div className="flex flex-col gap-y-4 mt-4 md:gap-y-2">
              <h3 className="text-lg font-semibold">Every downstream file</h3>
              <p className="text-sm">
                All the files your pipeline produces are stored alongside the pipeline run history, making it super easy
                to find the correct files for future analysis
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EverythingYouNeed.propTypes = {};

export default EverythingYouNeed;