import React from 'react';
import PropTypes from "prop-types";

const SectionWrapper = ({ children, className = '' }) => {
  return (
    <div className={`pb-20 ${ className}`}>
      { children }
    </div>
  );
};

SectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default SectionWrapper;