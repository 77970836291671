import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => {
  return (
    <div className="bg-white py-12 px-6 sm:pt-16 sm:pb-8 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        {/* <p className="text-lg font-semibold leading-8 tracking-tight text-[#3f3f3f] mb-8">
          A 3-minute explainer
        </p> */}
        <div class="aspect-w-16 aspect-h-9">
          <iframe
            src={`https://www.youtube.com/embed/${embedId}`}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default YoutubeEmbed;
