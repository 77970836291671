import React from 'react';
import TextInput from "./form/TextInput";
import TextArea from "./form/TextArea";
import { useState } from 'react';

const ContactForm = () => {

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const submit = e => {
    e.preventDefault();
    setLoading(true);
    fetch("/contact/", {
      method: "POST",
      body: new URLSearchParams({email, message})
    }).then(resp => resp.json()).then(json => {
      setLoading(false);
      if (json.success) {
        setEmail("");
        setMessage("");
      }
    });
  };

  return (
    <div className="p-16 md:mx-10 mt-10 lg:mt-0 bg-[#C6BDFF] flex flex-col rounded lg:rounded-lg flex-1" onSubmit={submit}>
      <form className="space-y-4" action="#" method="post">
        <TextInput
          id="email" label="Email" type="email"
          value={email} onChange={e => setEmail(e.target.value)}
        />
        <TextArea
          id="message" label="Message" defaultValue="" rows={3}
          value={message} onChange={e => setMessage(e.target.value)}
        />
        <button
          type="submit"
          className={`rounded bg-[#1BB8D9] hover:bg-blue-400 transition px-5 py-3 text-sm text-center text-white w-full ${!loading || "opacity-50 pointer-events-none"}`}
        >
					Get in touch with us
        </button>
      </form>
    </div>
  );
};

ContactForm.propTypes = {

};

export default ContactForm;