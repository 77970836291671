import React, { useState } from 'react';
import TextInput from "./form/TextInput";
import TextArea from "./form/TextArea";

const GetStartedForm = () => {

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const submit = e => {
    e.preventDefault();
    setLoading(true);
    fetch("/contact/", {
      method: "POST",
      body: new URLSearchParams({email, message})
    }).then(resp => resp.json()).then(json => {
      setLoading(false);
      if (json.success) {
        setEmail("");
        setMessage("");
      }
    });
  };

  return (
    <div className="p-5 mt-10 md:mt-0 md:mx-10 bg-[#222938] flex flex-col rounded flex-1">
      <h3 className="text-center text-white text-lg font-semibold p-5">Get Started</h3>
      <form className="mx-2 lg:mx-5 xl:mx-10 mb-5 space-y-4" onSubmit={submit}>
        <TextInput
          id="email" label="Email" type="email"
          value={email} onChange={e => setEmail(e.target.value)}
        />
        <TextArea
          id="message" label="Message" defaultValue="" rows={3}
          value={message} onChange={e => setMessage(e.target.value)}
        />
        <button
          type="submit"
          className={`rounded bg-theme-blue-dark hover:bg-theme-blue-light transition px-5 py-3 text-sm text-center text-white w-full ${!loading || "opacity-50 pointer-events-none"}`}
        >
          Request a walkthrough
        </button>
        <span className="block text-end text-sm">or <a className="text-white" href="#">sign up for free</a></span>
      </form>
    </div>
  );
};

GetStartedForm.propTypes = {

};

export default GetStartedForm;