import React from "react";

const WhatIsFlow = () => {
  return (
    <div className="text-center mt-5 px-5 max-w-5xl mx-auto">
      <h1 className="font-bold text-3xl my-2">What is Flow?</h1>
      <p className="max-w-lg mx-auto">
				Flow lets wet lab biologists run bioinformatics workflows via a simple web interface, store and structure the resulting files -<br /> <span className="font-semibold">and makes data sharing simple</span>.
      </p>
      <div className="flex flex-col items-center justify-center gap-y-10 gap-x-5 mt-10 md:flex-row text-theme-blue-light">
        <p className="basier md:flex-1 text-xl md:max-w-[240px] font-semibold">
					Run sequencing workflows in one click
        </p>
        <h2 className="basier md:flex-1 text-xl md:max-w-[240px] font-semibold">
					Automatically structure experimental files
        </h2>
        <h2 className="basier md:flex-1 text-xl md:max-w-[240px] font-semibold">
					Compare, share and publish data easily
        </h2>
      </div>
    </div>
  );
};

WhatIsFlow.propTypes = {

};

export default WhatIsFlow;