import React from "react";
import Base from "./Base";

const IndustryPage = () => {
  return (
    <Base>
      Flow For Industry
    </Base>
  );
};

IndustryPage.propTypes = {
  
};

export default IndustryPage;