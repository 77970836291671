import React, {useState} from "react";
import FlowDescriptorOptionsOption from "./FlowDescriptorOptionsOption";

const FlowDescriptorOptions = () => {
  const [selected, setSelected] = useState(0);



  return (
    <div className="mt-20">
      <div className="max-w-5xl lg:rounded-lg bg-slate-100 mx-auto lg:p-10">
        <h1 className="text-2xl text-center lg:text-start basier font-bold px-10 pt-10 lg:px-0">Flow is both</h1>
        <div className="flex flex-col lg:flex-row gap-x-12 pt-5">
          <div className="basier flex flex-col p-10 lg:p-0 lg:w-fit gap-y-2 lg:gap-y-5 text-lg font-semibold bg-slate-500 lg:bg-transparent items-center">
            <FlowDescriptorOptionsOption isSelected={selected === 0} onSelect={() => setSelected(0)}>
							a bioinformatics analysis platform
            </FlowDescriptorOptionsOption>
            <FlowDescriptorOptionsOption isSelected={selected === 1} onSelect={() => setSelected(1)}>
							a bioinformatics data storage system
            </FlowDescriptorOptionsOption>
          </div>

          <p className="flex-1 text-center lg:text-start p-10 lg:pt-0 max-w-xl mx-auto lg:max-w-5xl">
            {snippets[selected]}
          </p>
        </div>
      </div>
    </div>
  );
};

FlowDescriptorOptions.propTypes = {
};

const snippets = [
  'Flow allows researchers to upload sequencing datasets and run bioinformatics analyses on their data directly within the platform. Each processing step in an Flow workflow is presented in an easy to understand way - you can view and download the step\'s input and output files, and see its command output.',
  'Flow helps researchers to store biological data in a structured and useful way. Building on over a decade of bioinformatics insight, Flow takes an opinionated approach to biological data storage, tying data directly to the analysis that produces it. This allows you to always find the files you need and any corresponding files further down the analysis chain.'
];

export default FlowDescriptorOptions;