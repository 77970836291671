import React from 'react';
import ResponsiveLinkButton from "../ResponsiveLinkButton";

import logoTwitter from '../../images/logos/twitter@3x.png';
import logoSlack from '../../images/discourse.svg';
import logoDocs from '../../images/logos/docs@3x.png';

const Community = () => {
  return (
    <div className="basier max-w-3xl m-auto pt-20 gap-y-5 flex flex-col px-5 text-center">
      <h2 className="font-semibold text-2xl">Flow's Community</h2>
      <p className="text-theme-blue-dark text-lg">Flow has a growing community of helpful, friendly users, along with a responsive core team of developers to answer your questions.</p>
      <div className="flex flex-col md:flex-row md:justify-between mt-5 gap-y-10 gap-x-5">
        <div className="flex flex-col gap-y-5 items-center">
          <img alt="Join our community" src={logoSlack} width={128} height={128} />
          <div className="flex max-w-[300px] m-auto">
            <ResponsiveLinkButton isExternal href="https://forum.flow.bio/">Join our Community</ResponsiveLinkButton>
          </div>
        </div>
        <div className="flex flex-col gap-y-5 items-center">
          <img alt="Read our documentation" src={logoDocs} width={128} height={128} />
          <div className="flex max-w-[300px] m-auto">
            <ResponsiveLinkButton isExternal href="https://docs.flow.bio">Read our documentation</ResponsiveLinkButton>
          </div>
        </div>
        <div className="flex flex-col gap-y-5 items-center">
          <img alt="Follow us on Twitter" src={logoTwitter} width={128} height={128} />
          <div className="flex max-w-[300px] m-auto">
            <ResponsiveLinkButton isExternal href="https://twitter.com/goodwright_">Follow us on Twitter</ResponsiveLinkButton>
          </div>
        </div>
      </div>
    </div>
  );
};

Community.propTypes = {

};

export default Community;