import React from "react";
import { Route } from "react-router";
import { BrowserRouter, Routes } from "react-router-dom";
import AboutPage from "../pages/AboutPage";
import BiologistsPage from "../pages/BiologistsPage";
import DatabasePage from "../pages/DatabasePage";
import DevelopersPage from "../pages/DevelopersPage";
import HomePage from "../pages/HomePage";
import IndustryPage from "../pages/IndustryPage";
import InstitutesPage from "../pages/InstitutesPage";
import WorkflowsPage from "../pages/WorkflowsPage";
import TermsAndConditionsPage from "../pages/TermsAndConditionsPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import FAQsPage from "../pages/FAQsPage";
import ScrollToTop from './ScrollToTop';

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/for/biologists/" exact element={<BiologistsPage />} />
        <Route path="/for/developers/" exact element={<DevelopersPage />} />
        <Route path="/for/companies/" exact element={<InstitutesPage />} />
        <Route path="/for/industry/" exact element={<IndustryPage />} />
        <Route path="/workflows/" exact element={<WorkflowsPage />} />
        <Route path="/database/" exact element={<DatabasePage />} />
        <Route path="/faqs/" exact element={<FAQsPage />} />
        <Route path="/about/" exact element={<AboutPage />} />
        <Route path="/about/terms-and-conditions/" exact element={<TermsAndConditionsPage />} />
        <Route path="/about/privacy-policy" exact element={<PrivacyPolicyPage />} />
      </Routes>
    </BrowserRouter>
  );
};

App.propTypes = {
  
};

export default App;