import React from "react";
import Base from "./Base";
import Faqs from "../components/home/Faqs";
import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import Footer from "../components/Footer";

const faqs = [
  {
    question: "What are the benefits of Flow?",
    answer:
      "Flow offers a unique combination of features, in being both a resource for storing bioinformatics datasets, and an analysis platform to run curated Nextflow pipelines on these datasets. In this way, Flow lets researchers analyse their data in the context of hundreds of other datasets, with intuitive and interactive visualisations.",
  },
  {
    question: "How is Flow funded?",
    answer:
      "The development of Flow is currently funded by the Dementia Research Institute at KCL, meaning we can offer the platform for free to individual labs and scientists, both for storing and analysing data. We offer custom Flow installs and site licences for use on a per-workflow basis to institutes, industry and biotech customers.",
  },
  {
    question: "What workflow languages does Flow support?",
    answer:
      "Flow is built around the Nextflow workflow language, which is increasingly being used in bioinformatics. We do not support WDL or CWL workflows at present.",
  },
  {
    question: "Is my data secure, both from competitors and intruders?",
    answer:
      "Yes. At the software level, Flow utilises industry-standard encryption measures to ensure data security. At the server level, we follow strict best-practice security policies. Within Flow itself, access to your data is restricted by default until you choose to share it. You have fine-grained control over who can see, edit and share your data.",
  },
  {
    question: "Can I request pipelines / I don’t see X pipeline?",
    answer:
      "We’re adding new pipelines for different analysis methods to Flow all the time - though if you’re interested in getting involved with developing Flow pipelines, or want to request something specific, please feel free to join our Slack and ask questions - we’d love to hear from you!",
  },
  {
    question: "Is my data backed up?",
    answer:
      "At the hardware-level, the academic version of Flow is hosted on King’s College London’s cloud infrastructure, and has frequent off-site backups. At the platform-level, we implement checksum validation to ensure data integrity.",
  },
];

const FAQsPage = () => {
  return (
    <Base isDark>
      <div className="bg-gray-900">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:py-40 lg:px-8">
          <div className="mx-auto max-w-4xl divide-y divide-white/10">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">
              Frequently asked questions
            </h2>
            <dl className="mt-10 space-y-6 divide-y divide-white/10">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt>
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                          <span className="text-base font-semibold leading-7">
                            {faq.question}
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <PlusSmallIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            ) : (
                              <MinusSmallIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base leading-7 text-gray-300">
                          {faq.answer}
                        </p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <Footer />
    </Base>
  );
};

export default FAQsPage;
