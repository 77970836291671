import React, { useState, useRef, useEffect} from "react";
import { Link } from "react-router-dom";
import biologistsIcon from "../images/biologists.svg";
import developersIcon from "../images/terminal.svg";
import industryIcon from "../images/industry.svg";
import instituteIcon from "../images/institute.svg";
import { ReactComponent as Logo } from "../images/logo.svg";
import { ReactComponent as DownArrow } from "../images/icons/down.svg";

const Nav = ({ isDark }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [forOpen, setForOpen] = useState(false);
  const [scroll, setScroll] = useState(0);

  const pxLine = 50;
  const opacity = scroll > pxLine ? 1 : scroll / pxLine;

  const authButtonClass = "w-32 flex justify-center px-6 py-2 rounded font-regular text-regular transition";
  const forLinkRow = `transition flex gap-2 items-center py-2 px-2 rounded-md ${ isDark ? 'hover:bg-[#444]' : 'hover:bg-gray-100'}`;
  const forLinkImg = "h-8 w-16";

  let dropdownActiveClass = isDark ? 'bg-[#444]' : 'bg-gray-100';

  const forRef = useRef();
  const menuRef = useRef();

  const path = window.location.pathname;


  useEffect(() => {
    const onResize = () => {
      setIsOpen(false);
      setForOpen(false);
    };
    const onClick = e => {
      if (forRef.current && forRef.current.contains(e.target)) return;
      if (menuRef.current && menuRef.current.contains(e.target)) return;
      setIsOpen(false);
      setForOpen(false);
    };
    const onSrcoll = () => setScroll(window.pageYOffset);
    window.addEventListener("resize", onResize);
    window.addEventListener("click", onClick);
    window.addEventListener("scroll", onSrcoll);
    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("click", onClick);
      window.removeEventListener("scroll", onSrcoll);
    };
  }, []);

  return (
    <nav
      className={`fixed top-0 left-0 w-screen ${opacity === 1 ? "shadow" : ""} ${isOpen ? isDark ? "gradient-dark-header-dropdown" : "bg-gradient-to-br" : "bg-white"} from-[#4F6ACA] to-[#5F40DD] px-6 sm:bg-none sm:px-8 md:px-10 z-30`}
      style={{background: isOpen ? undefined : `rgba(${ isDark ? '52, 56, 75' : '255, 255, 255' }, ${opacity})`}}
    >
      <div className="h-20 flex justify-between items-center">
        <Link to="/">
          <Logo alt="Flow" className="h-12 sm:hidden" {...(isOpen || isDark ? {fill: "white"} : {})} />
          <Logo alt="Flow" className="h-12 hidden sm:block transition" {...(isDark ? { fill: 'white' } : {})} />
        </Link>

        <div className="flex flex-col gap-1 h-7 w-9 relative cursor-pointer sm:hidden" onClick={() => setIsOpen(!isOpen)} ref={menuRef}>
          {[0, 1, 2].map(n => {
            const hide = isOpen && n === 1 ? "hidden" : "";
            const color = (isOpen || isDark) ? "bg-white" : "bg-[#3B59C3]";
            const center = isOpen ? "absolute top-3 bottom-3 left-0" : "relative";
            const angle = isOpen ? n === 0 ? "rotate-45" : "-rotate-45" : "";
            return (
              <div key={n} className={`w-9 h-1.5 ${hide} ${color} ${center} ${angle}`}>
                <div className={`absolute h-2 w-0.5 rounded-sm -top-px ${isOpen ? "bg-[#DFDFDF]" : "bg-[#344FAC]"}`}/>
                <div className={`absolute h-2 w-0.5 rounded-sm -top-px -right-0 ${isOpen ? "bg-[#DFDFDF]" : "bg-[#344FAC]"}`}/>
              </div>
            );
          })}
        </div>

        <div className={`hidden justify-between items-center ${ isDark ? 'text-white' : 'text-gray-800' } text-xs sm:flex sm:gap-16 md:text-sm md:gap-20 lg:gap-28`}>
          <div className="flex gap-4 md:gap-6">
            <div className={`flex relative items-center gap-1.5 cursor-pointer ${path.startsWith('/for/') ? 'text-[#1BB8D9]' : ''}`} onClick={() => setForOpen(!forOpen)} ref={forRef}>
              Flow for <DownArrow className={`w-3 h-3 ${forOpen ? "rotate-180" : ""}`} fill={path.startsWith('/for/') ? '#1BB8D9' : 'darkgrey'} />
              {forOpen && (
                <div className={`border-2 text-md  ${ isDark ? 'bg-[#373737] text-white border-[#414141]' : 'bg-white text-gray-600 border-gray-200' } absolute z-50 gap-2 top-8 font-medium -left-9 rounded py-2 px-2 whitespace-nowrap w-64 lg:w-96 flex flex-col shadow`}>
                  <Link to="/for/biologists/">
                    <div className={`${forLinkRow} ${path === "/for/biologists/" ? dropdownActiveClass : ""}`}>
                      <img src={biologistsIcon} alt="" className={forLinkImg} />
                      Flow for Biologists
                    </div>
                  </Link>
                  <Link to="/for/developers/">
                    <div className={`${forLinkRow} ${path === "/for/developers/" ? dropdownActiveClass : ""}`}>
                      <img src={developersIcon} alt="" className={forLinkImg} />
                      Flow for Developers
                    </div>
                  </Link>
                  <Link to="/for/companies/">
                    <div className={`${forLinkRow} ${path === "/for/companies/" ? dropdownActiveClass : ""}`}>
                      <img src={instituteIcon} alt="" className={forLinkImg} />
                      Flow for Companies
                    </div>
                  </Link>
                  {/* <Link to="/for/industry/">
                    <div className={`${forLinkRow} ${path === "/for/industry/" ? dropdownActiveClass : ""}`}>
                      <img src={industryIcon} alt="" className={forLinkImg} />
                      Flow for Industry
                    </div>
                  </Link> */}
                </div>
              )}
            </div>
            <Link to="/workflows/" className={path === "/workflows/" ? "text-[#3B59C3]" : ""}>Workflows</Link>
            <Link to="/database/" className={path === "/database/" ? "text-[#3B59C3]" : ""}>Database</Link>
            <Link to="/faqs/" className={path === "/faqs/" ? "text-[#3B59C3]" : ""}>FAQs</Link>
            <Link to="/about/" className={path === "/about/" ? "text-[#3B59C3]" : ""}>About</Link>
          </div>
          <div className="flex items-center gap-5">
            <a href="https://app.flow.bio/login/">Login</a>
            <a className={`${isDark ? 'bg-[#2aa1d7]' : 'bg-[#3B59C3]'} text-white py-2 px-6 rounded`} href="https://app.flow.bio/signup/">Signup</a>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="top-20 text-white sm:hidden">
          <div className="flex gap-12 text-base">
            <div className="flex flex-col gap-1.5">
              <Link to="/for/biologists/">Flow for Biologists</Link>
              <Link to="/for/developers/">Flow for Developers</Link>
              <Link to="/for/companies/">Flow for Companies</Link>
              {/* <Link to="/for/industry/">Flow for Industry</Link> */}
            </div>
            <div className="flex flex-col gap-1.5">
              <Link to="/workflows/">Workflows</Link>
              <Link to="/database/">Database</Link>
              <Link to="/faqs/">FAQs</Link>
              <Link to="/about/">About</Link>
            </div>
          </div>
          <div className="flex gap-3 py-6 text-base">
            <a href="https://app.flow.bio/login/" className={`${authButtonClass} bg-white text-[#4F6ACA]`}>Login</a>
            <a href="https://app.flow.bio/signup/" className={`${authButtonClass} bg-[#2aa1d7]`}>Sign up</a>
          </div>
        </div>
      )}
    </nav>
  );
};

Nav.propTypes = {
  
};

export default Nav;