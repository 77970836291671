import React from 'react';
import databaseHeader from '../../images/headers/database.svg';

const DatabaseHeader = () => {
  return (
    <div className={`pt-16 px-10 bosier text-white 5xl m-auto flex flex-col items-center`}>
      <img className="max-h-[660px] w-full" src={databaseHeader}/>
      <h1 className="text-4xl text-center max-w-2xl font-bold">
        All your complex bioinformatics data are
        <span className="text-[#1BB8D9]"> stored</span>,
        <span className="text-[#1BB8D9]"> structured</span>, and
        <span className="text-[#1BB8D9]"> searchable</span>
      </h1>
      <div className="mt-10 flex flex-col lg:flex-row gap-y-10 items-center lg:items-start">
        <div className="space-y-8 lg:space-y-4 mt-4 text-center lg:text-start max-w-lg lg:mr-10">
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-y-20 gap-x-16 items-top justify-center">
        <div className="space-y-2 text-center max-w-[320px] mx-auto">
          <span className="block font-semibold text-[#1BB8D9] text-lg">Stored</span>
          <p>
            Wet lab users can store terabytes of data for free, and don’t have to spend any time manually arranging
            files
          </p>
        </div>
        <div className="space-y-2 text-center max-w-[320px] mx-auto">
          <span className="block font-semibold text-[#1BB8D9] text-lg">Structured</span>
          <p>
            Data on Flow are consistently structured in Projects consistently each time pipelines are run, meaning no
            more files in strange places
          </p>
        </div>
        <div className="space-y-2 text-center max-w-[320px] mx-auto">
          <span className="block font-semibold text-[#1BB8D9] text-lg">Searchable</span>
          <p>
            Bioinformaticians can query data and files on Flow using a user-friendly Python API
          </p>
        </div>
      </div>
    </div>
  );
};

DatabaseHeader.propTypes = {};

export default DatabaseHeader;