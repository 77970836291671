import React from "react";
import Base from "./Base";
import GradientContainer from "../components/GradientContainer";
import IllustratedHeader from "../components/IllustratedHeader";
import pipelineMaintenance from "../images/headers/pipeline-maintanence.svg";
import Footer from "../components/Footer";
import FaqSection from "../components/sections/FaqSection";
import AnnotatedSection from "../components/sections/AnnotatedSection";

import runAnalysis from '../images/run-analysis.svg';
import openSource from '../images/open-source.svg';
import ColumnSection from "../components/sections/ColumnSection";
import ResponsiveLinkButton from "../components/ResponsiveLinkButton";

const WorkflowsPage = () => {
  return (<Base isDark>
    <GradientContainer gradient="gradient-workflows-page">
      <IllustratedHeader
        title={<span className="inline-block mt-10">Flow is the home for all your bioinformatics workflows</span>}
        description={
          <div className="space-y-8">
            <p>
              Flow has a range of curated pipelines for bioinformatics across genomics and transcriptomics. In addition to full pipelines, users can also run 
              individual subworkflows or tools from the Flow library
            </p>
            {/* <div className="my-6 hidden lg:block">
              <ResponsiveLinkButton href="#" className="py-4 bg-[#1BB8D9]">Browse our pipelines</ResponsiveLinkButton>
            </div> */}
          </div>
        }
        image={pipelineMaintenance}
      />
      <AnnotatedSection
        title={<h2>Workflows for a range of different sequencing analyses</h2>}
        description={<p>Bioinformaticians can upload their pipelines to Flow, wet lab biologists can run existing
          validated workflows, and developers can easily integrate pipelines into their existing software</p>}
        image={<img src={runAnalysis} className="w-[470px] mx-auto"/>}
        className="mt-16 md:mt-28 lg:mx-20 pb-0"
      />
      <AnnotatedSection
        reverse
        title={<h2>Open-source and community-tested</h2>}
        description={<p>A major benefit of Flow is the open-source and tested nature of all pipelines on the platform.
          All Nextflow pipelines deployed on Flow are validated and tested by the scientific community</p>}
        image={<img src={openSource} className="rounded mx-auto w-[170px]"/>}
        className=" mt-8 md:mt-28 lg:mx-20"
      />
    </GradientContainer>
    <GradientContainer gradient="gradient-page-lower">


      <ColumnSection
        title={
          <h2 className="text-start max-w-3xl self-start p-5 lg:p-0 mb-0">
            Don’t see it? We can add it
          </h2>
        }
        className="pb-24 max-w-7xl lg:pb-24 mx-auto text-md leading-loose text-[0.925rem] lg:text-base leading-relaxed lg:leading-[1.75] text-start items-start lg:space-y-2">
        <p className="p-5 lg:p-0 max-w-lg self-start">
          If your Nextflow workflow is nf-core compatible, it can easily be integrated into Flow. Just reach out to us
          and we’ll integrate it with ease.
        </p>

        <div className="self-start p-5 lg:p-0 space-x-4">
          <ResponsiveLinkButton
            href="mailto:admin@goodwright.org"
            className="py-4 bg-[#1BB8D9]"
            isExternal>
            Get in touch
          </ResponsiveLinkButton>

          {/* <span>or</span */}

          {/* <ResponsiveLinkButton
            href="#"
            className="py-4 bg-[#1BB8D9]">
            Request a pipeline
          </ResponsiveLinkButton> */}

        </div>
      </ColumnSection>
      <div className="h-[250px]" />
    </GradientContainer>
    <Footer/>
  </Base>);
};

WorkflowsPage.propTypes = {};

export default WorkflowsPage;