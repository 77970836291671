import React from 'react';
import PropTypes from "prop-types";
import Lottie from "lottie-react";

const FeaturesGridItem = ({ title, content, animation }) => {
  return (
    <div className="text-center md:text-start px-5 flex flex-col md:flex-row items-center">
      {/* <img alt={title} src={image} className="m-4" /> */}
      <Lottie 
        animationData={animation} 
        className="w-24 h-24 md:w-48 md:h-48 mx-auto md:mr-4" 
        loop={true}
      />
      <div className="flex flex-col">
        <h3 className="font-semibold text-lg text-[#18c7c7]">{title}</h3>
        <p>{content}</p>
      </div>
    </div>
  );
};

FeaturesGridItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string,
};

export default FeaturesGridItem;