import React from 'react';
import PropTypes from "prop-types";

const TextInput = ({ label, id, ...rest }) => {
  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-white">
        { label }
      </label>
      <div className="mt-1">
        <input
          id={id}
          {...rest}
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm text-black"
        />
      </div>
    </div>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  // extends 'input'.propTypes
};

export default TextInput;