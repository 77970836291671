import React from 'react';
import PropTypes from "prop-types";
import SectionWrapper from "./SectionWrapper";

const FaqSection = ({ title, questions, className = '' }) => {
  return (
    <SectionWrapper className={`text-center md:text-start ${ className }`}>
      <div className="max-w-7xl mx-auto px-5 md:px-20 space-y-6">
        <h3 className="font-semibold text-3xl">{ title }</h3>
        {questions.map(({ question, answer }) => (
          <div key={question} className="max-w-3xl">
            <h4 className="font-semibold text-lg">{question}</h4>
            <p>{answer}</p>
          </div>
        ))}
      </div>
    </SectionWrapper>
  );
};

FaqSection.propTypes = {
  title: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
  })),
  className: PropTypes.string,
};

export default FaqSection;