import React from 'react';
import PropTypes from "prop-types";
import IllustratedHeaderNavigation from "./IllustratedHeaderNavigation";

const IllustratedHeader = ({ image, title, description, withNavigation, isWide }) => {
  const width = isWide ? 'max-w-7xl' : 'max-w-5xl';

  return (
    <div className={`lg:pt-28 px-10 bosier text-white ${width} m-auto flex flex-col-reverse lg:flex-col`}>
      <div className="mt-10 flex flex-col lg:flex-row gap-y-10 items-center lg:items-start">
        <div className="space-y-8 lg:space-y-4 mt-4 text-center lg:text-start max-w-lg lg:mr-10">
          <h1 className="text-5xl font-bold">{ title }</h1>
          {description}
        </div>
        <div className="w-auto lg:mr-auto">
          <img className="max-w-[350px] lg:max-w-[483px] w-full" src={image} />
        </div>
      </div>

      { withNavigation && <IllustratedHeaderNavigation /> }
    </div>
  );
};

IllustratedHeader.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  withNavigation: PropTypes.bool,
  isWide: PropTypes.bool,
};

export default IllustratedHeader;