import React from 'react';
import PropTypes from "prop-types";

const GradientContainer = ({ className = '', gradient = '', children }) => {
  return (
    <div className={`${ className } relative`}>
      <div className={`gradient-page-base -z-20 inset-0 absolute`} />
      <div className={`${ gradient } -z-10 inset-0 absolute`} />
      { children }
    </div>
  );
};

GradientContainer.propTypes = {
  children: PropTypes.node,
  gradient: PropTypes.string,
  className: PropTypes.string,
};

export default GradientContainer;