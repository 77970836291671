import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from "prop-types";

import logoGoodwright from '../images/goodwright.svg';
import logoTwitter from '../images/icons/twitter.svg';
import logoGithub from '../images/icons/github.svg';
import logoSlack from '../images/discourse.svg';
import {Link} from "react-router-dom";

const Footer = ({ isDark }) => {
  return (
    <div className={`py-20 ${ isDark ? 'bg-[#161C28]' : 'bg-white'} px-10 md:px-0`}>
      <div className="md:w-1/2 md:px-0 ml-auto grid grid-cols-2 md:grid-cols-3 gap-4 text-sm">
        {linkSections.map(section =>
          <div key={section.label} className="flex flex-col gap-y-2">
            <h4 className="text-slate-500">{ section.label }</h4>
            {section.links.map(link =>
              <a href={link.href} className="text-[#A6A6A6]">{link.label}</a>
            )}
          </div>
        )}
      </div>

      <div className="flex flex-col-reverse md:flex-row items-center pt-5">
        <div className="w-1/2 flex justify-center items-center mt-5 md:mt-0">
          <img alt="Goodwright; Software for Scientists" src={logoGoodwright}/>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-x-10 gap-y-5">
          <div className="flex flex-row gap-x-4 justify-center md:justify-start">
            <a href="https://twitter.com/goodwright_"><img alt="Goodwright on Twitter" src={logoTwitter} /></a>
            <a href="https://github.com/goodwright"><img alt="Goodwright on Github" src={logoGithub} /></a>
          </div>
          {/* <div className="flex flex-row md:flex-col lg:flex-row gap-4 text-slate-500 text-sm">
            <a href="#" className="hover:text-slate-400">Terms and Conditions</a>
            <a href="#" className="hover:text-slate-400">Privacy Policy</a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  isDark: PropTypes.bool
};

const linkSections = [
  {
    label: 'Flow for',
    links: [
      { label: 'Flow for Biologists', href: '/for/biologists/' },
      { label: 'Flow for Developers', href: '/for/developers/' },
      { label: 'Flow for Institutes', href: '/for/institutes/' },
      // { label: 'Flow for Industry', href: '#' },
    ],
  },
  {
    label: 'Product',
    links: [
      { label: 'About Flow', href: '/about' },
      { label: 'FAQs', href: '/faqs' },
      { label: 'Workflows', href: '/workflows' },
      { label: 'Data Organisation', href: '/database' },
      { label: 'Docs', href: 'https://docs.flow.bio/' },
    ],
  },
  {
    label: 'Support',
    links: [
      { label: 'Contact Us', href: 'mailto:support@flow.bio' },
      { label: 'Who we are', href: 'https://goodwright.com' },
    ],
  },
];

export default Footer;