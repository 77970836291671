import React from 'react';
import FeaturesGridItem from "./FeaturesGridItem";

import api from "../../images/api.json";
import dna from "../../images/dna.json";
import fileSharing from "../../images/file-sharing.json";
import lineChart from "../../images/line-chart.json";
import server from "../../images/server.json";
import hardDrive from "../../images/hard-drive.json";
import upload from "../../images/upload.json";


const FeaturesGrid = ({ title, content, image }) => {
  return (
    <div className="max-w-5xl m-auto pt-20 grid grid-flow-row grid-cols-1 md:grid-flow-col md:grid-cols-2 md:grid-rows-3 gap-8">
      <FeaturesGridItem
        title="Dynamic Visualisations"
        content="Flow provides intuitive visualisations for multiple different analyses, to help you understand your data."
        animation={lineChart} />
      <FeaturesGridItem
        title="Run pipelines effortlessly in the cloud"
        content="Flow has multiple validated nf-core Nextflow pipelines for analysing your sequencing data, and we're adding more every week."
        animation={upload} />
      <FeaturesGridItem
        title="A powerful API"
        content="For bioinformaticians comfortable with the command line, Flow has a GraphQL API for querying and manipulating your data."
        animation={api} />
      <FeaturesGridItem
        title="Built for bioinformatics"
        content="Flow has been designed from first principles around genomic filetypes and the downstream analyses you run on them."
        animation={dna} />
      <FeaturesGridItem
        title="Advanced Security and Privacy"
        content="Tightly scoped permissions, access controls, and strong data protection mean you can keep data and analysis as private as you like."
        animation={server} />
      <FeaturesGridItem
        title="Integrated Analyses"
        content="Unlike other platforms, Flow stores your data and your analysis history together, helping you analyse and explore your data in context."
        animation={fileSharing} />
    </div>
  );
};

FeaturesGrid.propTypes = {

};

export default FeaturesGrid;