import React from "react";
import curl from "../../images/curl.svg";
import screenshot from "../../images/screenshot.webp";
import docsIcon from "../../images/icons/docs.svg";
import WhatIsFlow from "./WhatIsFlow";

const HomeHeader = () => {
  return (
    <header className="px-6 pt-20 sm:px-8 md:px-10 bg-gradient-to-b from-[#B6BEDB]/20 via-[#AFA8D3]/20 to-[#FFFFFF]/20">
      <div className="flex flex-col max-w-7xl mx-auto py-4 gap-y-8 sm:py-6 md:py-8 md:flex-row lg:py-12 md:gap-x-6 ">
        <div className="md:w-3/5 flex-grow lg:w-1/3">
          <h1 className="basier gradient-text mb-2 font-semibold leading-10 text-3xl md:text-5xl mx-auto sm:leading-tight sm:mb-3 md:leading-tighter">
            End-to-end bioinformatics<br />in the cloud
          </h1>
          <p className="font-medium text-[#3F5585] text-md mb-2 max-w-sm mt-4 sm:text-xl leading-tight sm:max-w-md sm:mb-10">
            Flow helps life science teams analyse, store,
            curate and share biology data at scale
          </p>
          <div className="flex gap-8 text-sm items-center">
            {/* <a className="rounded px-5 py-3 bg-[#3B59C3] text-white" href="https://app.flow.bio">Try it out</a> */}
            {/* <a className="text-[#5589FB] flex items-center gap-2" href="https://docs.flow.bio">
              <img src={docsIcon} alt="" className="w-6" />
              Read the docs
            </a> */}
          </div>
        </div>
        <div className="lg:screenshot-header md:w-3/5 lg:w-3/5">
          <img src={screenshot} alt="Screenshot" className="w-full max-w-lg mx-auto shadow-sm rounded-md md:max-w-none md:mx-0" />
        </div>
      </div>

      <WhatIsFlow />
    </header>
  );
};

HomeHeader.propTypes = {
  
};

export default HomeHeader;