import React from 'react';
import PropTypes from "prop-types";
import SectionWrapper from "./SectionWrapper";

const AnnotatedSection = ({ title, description, image, reverse, className = '' }) => {
  const modifiedTitle = React.cloneElement(title, {
    className: `text-3xl text-white font-semibold basier ${ title.props?.className ?? '' }`,
  });
  const modifiedDescription = React.cloneElement(description, {
    className: `${ description.props?.className ?? '' } `,
  });
  const modifiedImage = React.cloneElement(image, {
    className: `pb-8 lg:pb-0 ${ image.props?.className ?? '' }`,
  });

  return (
    <SectionWrapper className={`${ className } px-5 lg:px-0`}>
      <div className={`max-w-7xl flex flex-col-reverse gap-y-4 lg:gap-x-8 ${ !!reverse ? 'lg:flex-row-reverse' : 'lg:flex-row' } mx-auto items-center `}>
        <div className="text-white space-y-4 max-w-[600px] mx-auto text-center lg:text-start">
          {modifiedTitle}
          {modifiedDescription}
        </div>
        {modifiedImage}
      </div>
    </SectionWrapper>
  );
};

AnnotatedSection.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  image: PropTypes.node.isRequired,
  reverse: PropTypes.bool,
  className: PropTypes.string,
};

export default AnnotatedSection;