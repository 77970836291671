import React from 'react';
import HowDoesFlowWorkSection from "./HowDoesFlowWorkSection";
import ArrowSvg from "../sprites/FlowingArrow";

import imageAnalysis from '../../images/analysis.svg';
import imageComputer from '../../images/computer-dna.svg';
import imageServer from '../../images/connected-server.svg';
import imageSequence from '../../images/sequence.svg';
import imageDatabase from '../../images/database.svg';


const HowDoesFlowWork = () => {
  return (
    <div className="gradient-bg-diffuse-blue py-12 md:py-28 mt-20 text-white">
      <div className="max-w-7xl m-auto md:px-10">
        <h2 className="basier font-semibold text-4xl text-center lg:text-start px-5 lg:px-0 mb-2">How does Flow work?</h2>
        <p className="text-lg text-center lg:text-start px-5 lg:px-0 lg:mb-16">Get your research answers in 5 simple steps</p>
        <div className="flex flex-col lg:flex-row lg:space-between items-center my-5 mt-20">
          <HowDoesFlowWorkSection image={imageComputer} description="Upload your experimental data (FASTA, GTF, BAM)" step={1} />
          <ArrowSvg className="text-[#3371FA] lg:-rotate-90 -mt-2 lg:mt-0 "/>
          <HowDoesFlowWorkSection image={imageServer} description="Automatically store standardised data" step={2} />
          <ArrowSvg className="text-[#3371FA] lg:-rotate-90 -mt-2 lg:mt-0 "/>
          <HowDoesFlowWorkSection image={imageSequence} description="Select parameters and run a pipeline" step={3} />
          <ArrowSvg className="text-[#3371FA] lg:-rotate-90 -mt-2 lg:mt-0 "/>
          <HowDoesFlowWorkSection image={imageAnalysis} description="Get your research insight and visualisations" step={4} />
          <ArrowSvg className="text-[#3371FA] lg:-rotate-90 -mt-2 lg:mt-0 "/>
          <HowDoesFlowWorkSection image={imageDatabase} description="Add your results to a growing public database" step={5} />
        </div>
      </div>
    </div>
  );
};

HowDoesFlowWork.propTypes = {

};

export default HowDoesFlowWork;