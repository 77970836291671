import React from "react";
import Base from "./Base";
import GradientContainer from "../components/GradientContainer";
import IllustratedHeader from "../components/IllustratedHeader";
import institutes from "../images/headers/institutes.svg";
import Footer from "../components/Footer";
import FaqSection from "../components/sections/FaqSection";
import AnnotatedSection from "../components/sections/AnnotatedSection";

import dataCenter from '../images/data-center.svg';
import dataStorage from '../images/data-storage.svg';
import twoFactor from '../images/2fa.svg';
import ColumnSection from "../components/sections/ColumnSection";
import ResponsiveLinkButton from "../components/ResponsiveLinkButton";

const InstitutesPage = () => {
  return (<Base isDark>
    <GradientContainer gradient="gradient-institutes-page">
      <IllustratedHeader
        withNavigation
        title={<span className="inline-block mt-10">Keep sequencing data organised as your team grows</span>}
        description={<p>
          Flow’s integrated design, combining both an analysis platform and database, means that your team’s data is
          structured from day one
        </p>}
        image={institutes}
      />
      <AnnotatedSection
        title={<h2>A centralised store for all your data and analysis</h2>}
        description={<p>All of a team’s data can be stored centrally, either integrated on-premises or deployed in the
          cloud. Flow provides multiple secure backup options to meet your needs, and keeps data and analyses in one place despite companies' staff turnover</p>}
        image={<img src={dataCenter} className="w-[350px] mx-auto"/>}
        className="mt-16 md:mt-28 lg:mx-20 pb-0"
      />
      <AnnotatedSection
        reverse
        title={<h2>Fine-grained data access controls</h2>}
        description={<p>Data can be shared with differing levels of permissions (read, write and share with others), and ownership
          can be granted and revoked on both a per-file and per-project basis. Data can be shared within subgroups of your company, or made publicly-facing in one click to showcase your team's research </p>}
        image={<img src={dataStorage} className="rounded mx-auto w-[368px]"/>}
        className=" mt-8 md:mt-28 lg:mx-20"
      />
    </GradientContainer>
    <GradientContainer gradient="gradient-page-lower">
      <AnnotatedSection
        title={<h2>Advanced data security, tailored for the complexities of biological data</h2>}
        description={<p>Biological data requires special attention, and we have implemented custom biological data structures combined with industry-standard encryption and security policies to ensure the security and safety of your data. Flow also has integrations with major Single Sign-On systems for your company-wide logins</p>}
        image={<img src={twoFactor} className="w-[260px] mx-auto"/>}
        className="pt-16 md:pt-28 lg:mx-20"
      />


      <ColumnSection
        title={<h2 className="text-start max-w-3xl self-start p-5 lg:p-0 mb-0">Flow is designed for
          life science companies</h2>}
        className="pb-24 max-w-7xl lg:pb-24 mx-auto text-md leading-loose text-[0.925rem] lg:text-base leading-relaxed lg:leading-[1.75] text-start items-start lg:space-y-2">
        <div className="lg:space-y-8">
          <p className="p-5 lg:p-0">
          Within a company it is common for teams to be continually producing new sequencing datasets. 
          At the team level, group leaders want to keep track of their datasets and ensure continuity between an ever-changing staff. 
          In an academic environment it is not uncommon for expensive datasets to slip between the cracks over a team’s lifetime. 
          You want to provide your researchers with ways to interrogate all the data generated between 
          all your teams and minimise the waste of duplicated efforts. Fostering an open culture of data sharing through Flow promotes 
          collaboration that might have previously been reliant on chance conversations.
          </p>
          <p className="p-5 lg:p-0">
          Furthermore, you may want to have an outward facing database, presenting a coherent picture of research at your company 
          to the wider research community, your funders and the general public. Flow is unique in allowing you to accomplish all of 
          these goals with minimal effort. Goodwright will help to design your company instance to meet your needs, taking the stress 
          away from your research scientists so they can focus on the biology.
          </p>
        </div>

        <div className="self-start p-5 lg:p-0">
          <ResponsiveLinkButton
            href="mailto:admin@goodwright.org"
            className="bg-[##1BB8D9] hover:bg-indigo-400 active:bg-indigo-600 py-4 px-8"
            isExternal>
            Get in touch
          </ResponsiveLinkButton>
        </div>
      </ColumnSection>
    </GradientContainer>
    <FaqSection
      title="Company FAQs"
      questions={[{
        question: 'What restrictions are there on Flow deployment?',
        answer: 'The Flow platform is entirely containerised using Docker, meaning Flow can be installed on your own hardware, or deployed on the cloud infrastructure of your choosing. Our team is open to ensuring the Flow configuration is tailor-fit to the setup at your company.'
      }, {
        question: 'How much will it cost?',
        answer: 'We appreciate that different companies have different requirements and we would like to hear about yours before providing a quote. Please get in touch and we can arrange a meeting to get to know your needs.'
      }, {
        question: 'What if we want to move our data elsewhere?',
        answer: 'Companies need to consider longevity and with the fast-paced development of bioinformatics solutions you may want to move data elsewhere in the future. With Flow you can rest assured that all data structures are neatly and logically organised in a production-grade industry-standard database which can easily be deployed in another environment in the future.'
      },]}
      className="bg-[#044233] text-white pb-24 pt-16"
    />
    <Footer/>
  </Base>);
};

InstitutesPage.propTypes = {};

export default InstitutesPage;
