import React from 'react';
import PropTypes from "prop-types";
import SectionWrapper from "./SectionWrapper";

const ColumnSection = ({title: initialTitle, children, className: initialClassName = ''}) => {
  const title = React.cloneElement(initialTitle, {
    className: `font-semibold text-3xl my-16 inline-block ${initialTitle.props?.className ?? ''}`,
  });

  const className = `text-center items-center flex flex-col mx-auto px-5 md:px-20 text-white gap-y-4 ${initialClassName}`;

  return (
    <SectionWrapper className={className}>
      {title}
      {children}
    </SectionWrapper>
  );
};

ColumnSection.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ColumnSection;