import React from "react";
import Base from "./Base";
import GradientContainer from "../components/GradientContainer";
import Footer from "../components/Footer";
import AnnotatedSection from "../components/sections/AnnotatedSection";

import ColumnSection from "../components/sections/ColumnSection";
import ResponsiveLinkButton from "../components/ResponsiveLinkButton";
import DatabaseHeader from "../components/database/DatabaseHeader";

import serverStatus from '../images/server-status.svg';
import fileOrganisation from '../images/file-organisation.svg';
import EverythingYouNeed from "../components/database/EverythingYouNeed";

const DatabasePage = () => {
  return (
    <Base isDark>
      <GradientContainer gradient="gradient-data-page">
        <DatabaseHeader/>

        <AnnotatedSection
          reverse
          title={<h2>Flow organises the huge volume of files that bioinformatics generates in a consistent way</h2>}
          description={<p>Bioinformaticians can upload their pipelines to Flow, wet lab biologists can run existing
            validated workflows, and developers can easily integrate pipelines into their existing software</p>}
          image={<img src={serverStatus} className="w-[545px] mx-auto"/>}
          className="mt-8 md:mt-24 lg:mx-20 pb-0"
        />
        <AnnotatedSection
          title={<h2>How does Flow’s file organisation work?</h2>}
          description={<p>Data on Flow is organised into Projects, which contain runs of experiments and their
            associated
            data</p>}
          image={<img src={fileOrganisation} className="w-[590px] mx-auto order-first lg:order-last mt-8 mb-16"/>}
          className="mt-8 md:mt-8 lg:mx-20 pb-0"
        />

      </GradientContainer>

      <EverythingYouNeed />

      <div className="bg-[#161C28]">
        <ColumnSection
          title={<h2 className="mb-4">Every step completely reproducible, every time</h2>}
          className="pb-24 lg:pb-24 max-w-4xl gap-y-12 mx-auto text-md leading-loose text-[0.925rem] lg:text-base leading-relaxed lg:leading-[1.75]">
          <p className="p-5 lg:p-0">
            Flow was built with data provenance in mind. How do you, as a research scientist, prove your results? Flow has you covered.
          </p>
          <p className="p-5 lg:p-0">
            Flow tracks <span className="font-semibold">who ran what</span> analysis and when, so for publishing papers
            or for medical authority audits, <span className="text-[#1BB8D9] font-semibold">you can prove how you got your results</span> effortlessly.
          </p>
          <p className="p-5 lg:p-0">
            Flow is built on top of the workflow language Nextflow. Every time you run a pipeline, Flow creates a Docker
            container for each bioinformatics tool and runs it in an isolated container. The input files to the container
            are recorded, so Flow knows what tools ran with what inputs, in what computational environment, and what
            outputs were produced.
          </p>
        </ColumnSection>
      </div>

      <Footer/>
    </Base>
  );
};

DatabasePage.propTypes =
  {}
;

export default DatabasePage;