import React from "react";
import Base from "./Base";
import GradientContainer from "../components/GradientContainer";
import Footer from "../components/Footer";
import IllustratedHeader from "../components/IllustratedHeader";
import AnnotatedSection from "../components/sections/AnnotatedSection";
import ColumnSection from "../components/sections/ColumnSection";

import biologist from '../images/headers/biologist.svg';
import report from '../images/screenshots/multiqc_report.png';
import analysis from '../images/screenshots/clip-analysis@3x.webp';
import FaqSection from "../components/sections/FaqSection";

const BiologistsPage = () => {
  return (
    <Base isDark>
      <GradientContainer gradient="gradient-biologists-page">
        <IllustratedHeader
          withNavigation
          title={
            <span className="my-10 lg:my-4 inline-block">
              Analysis in <span className="text-[#1BB8D9]">a click</span>
            </span>
          }
          description={
            <p>
              Flow has been designed in collaboration with wet lab scientists
              to make it simple for biologists to run complex, compute-heavy pipelines
            </p>
          }
          image={biologist}
        />

        <AnnotatedSection
          className="mt-16 md:mt-28 lg:mx-20"
          title={<h2>View analysis tool output directly on the platform</h2>}
          description={<p>Flow has tools like MultiQC, IGV (Integrative Genomics Viewer) and more built directly in to
            the website, to make viewing your bioinformatics files simple.</p>}
          image={<img src={report} alt="A MultiQC Report generated during pipeline execution" className="w-[600px]"/>}
        />
      </GradientContainer>
      <GradientContainer gradient="gradient-page-lower">
        <AnnotatedSection
          reverse
          title={<h2 className="text-[#2BA5D7] px-10">Your data and analysis, all in one place</h2>}
          description={<p className="lg:px-10">No more headaches navigating messy folders of data. Know exactly what analysis produced your results today, two months from now, or two years from now. Say goodbye to data loss when lab members say goodbye.</p>}
          image={
            <img
              src={analysis}
              alt="A dashboard showing process executions alongside data files and parameters"
              className="w-[500px] lg:-ml-2"
              style={{filter: 'drop-shadow(0px 4px 17px rgba(255, 255, 255, 0.6))'}}
            />
          }
        />
        <ColumnSection title={<h2>Removing barriers to high quality research</h2>} className="pb-24 lg:pb-24 max-w-4xl mx-auto text-md leading-loose text-[0.925rem] lg:text-base leading-relaxed lg:leading-[1.75]">
          <p className="p-5 lg:p-0">Flow is the result of over ten years of development within the lab of Professor Jernej Ule, leader of the RNA Networks Laboratory and Centre Director of UK Dementia Research Institute at King’s College London. Over this time, groups from all over the world have used previous iterations of the platform to analyse and share data for their publications. Flow represents the latest stage of our joint vision to bring accessible, reliable and reproducible bioinformatics to the global research community.</p>
          <p className="p-5 lg:p-0">Standards in bioinformatics analysis are developing at an incredible pace. Reproducible, open, version-controlled workflows are now widely expected to be used in routine analysis. The effort involved however in producing and sharing such workflows can be enormous. Flow helps to minimise your time spent on meeting these demands by providing open gold-standard analysis options powered by Nextflow. </p>
          <p className="p-5 lg:p-0">Futhermore, biologists increasingly produce many orthogonal high-throughput datasets in pursuit of their scientific goals. Integrating these different datasets is often highly desired but the “how?” can feel like a black box. We are continually adding new ways of integrating and exploring your datasets to Flow via interactive visualisations.</p>
        </ColumnSection>
      </GradientContainer>
      <FaqSection
        title="Biologist FAQs"
        questions={[
          {
            question: 'Is my data really private?',
            answer: 'Our secure data structures mean that if your data is set to be only accessible to you, then it really is. Even our admins will have no access to that data unless you grant it to them. Industry-standard encryption and authentication means that your data is only for you until you’re ready to share it.'
          },
          {
            question: 'How do I know what is being done to my data?',
            answer: 'All of the bioinformatics code run on Flow is openly accessible in GitHub repositories. All workflows are written in the widely-used language, Nextflow. Many of our workflows are distributed through nf-core, which means they have already been widely tested by users from around the world. Unlike other providers, we don’t expect you to simply take our word for it that our code is reliable. '
          },
          {
            question: 'Can I use Flow for my publications?',
            answer: 'Yes! Many users have already cited projects on previous versions of the Flow server (iCount, iMaps) in their published research. Making your project public is a great way to share your raw data and analyses with reviewers and readers of your manuscript.'
          },
        ]}
        className="bg-[#130D30] text-white pb-24 pt-16"
      />
      <Footer/>
    </Base>
  );
};

BiologistsPage.propTypes = {};

export default BiologistsPage;