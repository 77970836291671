import React from "react";
import Base from "./Base";
import GradientContainer from "../components/GradientContainer";
import IllustratedHeader from "../components/IllustratedHeader";
import developers from "../images/headers/developers.svg";
import Footer from "../components/Footer";
import FaqSection from "../components/sections/FaqSection";
import AnnotatedSection from "../components/sections/AnnotatedSection";

import python from '../images/python.svg';
import graphql from '../images/graphql.svg';
import ColumnSection from "../components/sections/ColumnSection";
import ArrowSvg from "../components/sprites/FlowingArrow";
import toGui from "../images/screenshots/from-json-gui@3x.png";
import fromJson from "../images/screenshots/from-json-json@3x.png";
import commandLine from "../images/command-line.svg";

const DevelopersPage = () => {
  return (
    <Base isDark>
      <GradientContainer gradient="gradient-bioinformatics-page">
        <IllustratedHeader
          withNavigation
          title={<span className="inline-block mt-10 lg:mt-0">Biologists and bioinformaticians collaborating instantly</span>}
          description={
            <p>
              Flow is designed to reduce the overheads between bioinformaticians and wet lab biologists. It lets bioinformaticians write simple, reproducible GUIs for their workflows in minutes, that wet lab
              biologists can then run with the click of a button.
            </p>
          }
          image={developers}
        />

        <AnnotatedSection
          title={<h2>A modern platform built on Python and Nextflow</h2>}
          description={<p>The bioinformatics side of Flow is built around Python, the dominant language in biology and modern science, with pipelines developed in Nextflow. With an intuitive, functional interface, bioinformaticians and scientific developers can interact with vast amounts of data in advanced ways.</p>}
          image={<img src={python} className="w-[160px] mx-auto"/>}
          className="mt-16 md:mt-28 lg:mx-20"
        />
        <AnnotatedSection
          reverse
          title={<h2>A powerful, intuitive API</h2>}
          description={<p>Flow offers a GraphQL API that allows bioinformaticians to query all aspects of their data,
            across multiple datasets (including any public datasets on Flow).</p>}
          image={<img src={graphql} className="rounded mx-auto w-[430px]"/>}
          className="mt-8 md:mt-28 lg:mx-20"
        />
      </GradientContainer>
      <GradientContainer gradient="gradient-page-lower">
        <ColumnSection
          className="max-w-7xl"
          title={<h2 className="w-full text-center lg:text-start mb-4">From JSON to a full GUI</h2>}>
          <p className="lg:text-start max-w-3xl self-start">Flow lets bioinformaticians write one simple JSON file, which then produces a full GUI on the platform for
            their pipeline (complete with filetype and input validation) - no more sending wet lab biologists a Nextflow
            script and spending hours configuring environments and systems.</p>
          <div className="flex flex-col lg:flex-row lg:space-between items-center py-10">
            <img src={fromJson} className="w-[300px]" alt="An input JSON file that will create a full interface for the pipeline"/>
            <ArrowSvg className="text-[#384172] lg:-rotate-90 my-10 lg:mx-20 scale-150 shrink-0"/>
            <img src={toGui} className="w-[390px]" alt="A GUI built from the input JSON file"/>
          </div>
        </ColumnSection>
        <ColumnSection
          title={<h2 className="mb-4">From command line to data visualisation</h2>}>
          <img src={commandLine} className="w-[750px]"/>
        </ColumnSection>
      </GradientContainer>
      <FaqSection
        title="Bioinformatician FAQs"
        questions={[
          {
            question: 'Where can I see the code that is run on the platform?',
            answer: 'All of the bioinformatics analysis code is available in our GitHub repository. Workflows available on Flow are a combination of nf-core Nextflow pipelines and bespoke Nextflow pipelines that have been developed with our academic collaborators. If you think changes should be made, anyone can raise a GitHub issue, or make a comment in our responsive Slack community. '
          },
          {
            question: 'I don’t want to use the GUI, is there another way to access Flow?',
            answer: 'Flow offers a GraphQL API that allows bioinformaticians to query all aspects of their data, across multiple datasets, including any public datasets on Flow. You can also batch submit new data to the platform from your terminal. Additionally any of Flow’s tools can be run on the command line if you simply want to run the analysis for yourself. '
          },
          {
            question: 'How can I add my own workflows to Flow?',
            answer: 'Any modular DSL2 Nextflow workflow can be made Flow-compatible with the addition of a simple JSON script, which allows you to choose what is exposed to the user via the web platform. Currently, deployment requires interaction with our team. However, we are working on a system to support permission structures, version control and sharing of a user’s own workflows without our direct involvement.'
          },
        ]}
        className="bg-[#38062D] text-white pb-24 pt-16"
      />
      <Footer/>
    </Base>
  );
};

DevelopersPage.propTypes = {};

export default DevelopersPage;