import React from "react";
import Base from "./Base";

const PrivacyPolicyPage = () => {
  return (
    <Base>
      <article className="prose max-w-5xl mx-auto mt-48 px-10">
        <h1>Privacy Policy</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque accumsan sodales eros at ultricies. Sed
          egestas eu neque porttitor suscipit. In aliquet tempor diam ac condimentum. Duis odio tortor, tempus eu nunc
          ut, tincidunt congue nisl. Duis interdum, purus sed lacinia pulvinar, ipsum ligula pretium justo, eget
          eleifend velit nisi ut ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
          turpis egestas. Fusce iaculis porta mauris ac molestie. In hac habitasse platea dictumst.
        </p>

        <p>
          Quisque congue commodo leo convallis vulputate. Sed volutpat ipsum sed luctus tristique. Mauris eu massa at ex
          venenatis sodales. Proin ut feugiat tellus. Proin ut mi mi. Integer eget pellentesque nisi, vel sodales elit.
          Vivamus vel ante gravida, ultrices ligula eget, molestie libero. Vestibulum et neque lacinia, placerat tortor
          ac, pharetra metus. Ut dictum lacinia ornare. Vestibulum in nibh euismod, lacinia lorem in, egestas quam.
          Vestibulum mattis vitae enim eu efficitur. Nullam venenatis eros sed lacus semper blandit. Donec viverra sem
          et orci consequat, sit amet semper mauris congue.
        </p>

        <p>
          Etiam convallis, leo fermentum congue fringilla, ligula est sodales magna, in tempor urna nisi vitae augue.
          Sed a nisi ultrices orci ultrices luctus. Donec erat lacus, ornare nec suscipit non, commodo ut nibh. Fusce
          laoreet pellentesque lectus. Vestibulum et mauris nunc. Proin felis elit, aliquet ac volutpat ut, placerat
          quis dui. Aliquam velit lectus, viverra ac metus id, tempor sodales nisi. Vivamus eu auctor libero. Maecenas
          eros libero, scelerisque sit amet elementum sit amet, fermentum et felis. Nam tristique, nibh a elementum
          euismod, ipsum tellus luctus ante, nec imperdiet nisl erat non urna. Vivamus et magna at elit fringilla
          viverra sed in odio. Aliquam faucibus consequat libero, a bibendum est dictum eu.
        </p>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec arcu eros, pellentesque nec luctus ac,
          vestibulum blandit mauris. Praesent in nisi a quam vestibulum aliquet sed ut elit. Aliquam sollicitudin ex
          volutpat urna porttitor, et mollis ligula gravida. Nunc sagittis mi eu diam fringilla, vel porta nunc blandit.
          Vivamus ut risus ipsum. Donec est est, ullamcorper nec massa vitae, tristique lobortis tortor. Proin accumsan
          ante ac massa finibus, feugiat sagittis sapien convallis. Morbi pretium id justo ut blandit. Interdum et
          malesuada fames ac ante ipsum primis in faucibus.
        </p>

        <p>
          Vivamus hendrerit lacus lacus, non commodo ligula commodo at. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Nulla gravida dui ut leo pellentesque, ac ultrices arcu rutrum. Fusce rutrum lacus at tempor
          fringilla. Integer ex risus, mattis at posuere sed, mollis vitae ante. Ut malesuada dolor eu leo interdum, a
          pharetra libero imperdiet. Maecenas consectetur magna odio. Morbi et diam id ex tempor hendrerit a bibendum
          mauris. Ut cursus eros ac arcu venenatis, ut dapibus risus interdum. Nullam vitae vulputate velit. Fusce
          porta, purus eu vulputate ultricies, ipsum lorem tempus est, quis imperdiet elit sapien vel eros.
        </p>
      </article>
    </Base>
  );
};

PrivacyPolicyPage.propTypes = {};

export default PrivacyPolicyPage;