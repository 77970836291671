import React from 'react';
import PropTypes from "prop-types";

const TextArea = ({ label, id, ...rest }) => {
  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-white">
        { label }
      </label>
      <div className="mt-1">
        <textarea
          id={id}
          {...rest}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm text-black p-2"
        />
      </div>
    </div>
  );
};

TextArea.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  // extends 'textarea'.propTypes
};

export default TextArea;