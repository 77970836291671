import React from 'react';
import PropTypes from "prop-types";
import ResponsiveLinkButton from "../ResponsiveLinkButton";

const TargetAudiencesOption = ({ title, content, link }) => {
  return (
    <div className="text-center md:text-start flex flex-col gap-y-2 md:gap-y-4 justify-between">
      <h3 className="font-semibold text-lg">{title}</h3>
      <p>{content}</p>
      <ResponsiveLinkButton href={link}>
				Find out more
      </ResponsiveLinkButton>
    </div>
  );
};

TargetAudiencesOption.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  link: PropTypes.string.isRequired,
};

export default TargetAudiencesOption;