import * as React from "react";
import PropTypes from "prop-types";

const HowDoesFlowWorkSection = ({ image, description, step }) => (
  <div className="flex flex-col items-center md:flex-1 p-5 md:p-0">
    <img src={image} className="mb-4 lg:mb-12 flex-shrink-0"/>
    <div className="flex flex-row gap-x-5 items-center">
      <span className="mt-1 aspect-square flex h-10 w-10 rounded justify-center items-center font-semibold bg-[#1BB8D9]">{String(step)}</span>
      <span className="text-sm">{description}</span>
    </div>
  </div>
);

HowDoesFlowWorkSection.propTypes = {
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
};

export default HowDoesFlowWorkSection;
